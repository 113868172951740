import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledClean = styled('div')`
  width: 100vw;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 10rem 0;
`;

export const StyledContent = styled('div')`
  max-width: 144rem;
  width: 100%;
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
  padding: 4rem 2rem;
  margin-bottom: 7rem;
`;

export const StyledTitle = styled('h2')`
  align-self: center;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.8rem;
  text-transform: uppercase;
  ${({ theme }) => theme.mq.md} {
    font-size: 4.4rem;
    line-height: 5.2rem;
  }
`;

export const StyledSubtitle = styled('span')`
  display: none;
  align-self: center;
  font-weight: 400;
  font-size: 2.8rem;
  line-height: 4.2rem;
  margin-top: 4.8rem;
  padding: 0 1rem;
  ${({ theme }) => theme.mq.md} {
    display: block;
  }
`;

export const StyledProducts = styled('div')`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  min-height: 300px;
  position: relative;

  ${({ theme }) => theme.mq.smm} {
    min-height: 500px;
  }
  ${({ theme }) => theme.mq.md} {
    min-height: 700px;
  }
  ${({ theme }) => theme.mq.xxl} {
    min-height: 80vh;
  }
`;

export const StyledProduct = styled(BackgroundImage)`
  width: 25%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  *::before,
  *::after {
    background-position: center center !important;
    background-size: cover !important;
  }
`;

export const StyledProductTitle = styled.h3`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 3.5rem;
  line-height: 3.5rem;
  display: none;

  ${({ theme }) => theme.mq.xl} {
    display: block;
  }
`;
