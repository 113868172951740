import React from 'react';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledContainer,
  StyledWrapper,
  StyledTitle,
  StyledParagraph,
  StyledColumn,
} from './EveryAngleStyles';

const EveryAngle = ({ data }) => (
  <StyledBackground
    $url={data.backgroundImage.localFile.childImageSharp.original.src}
  >
    <StyledContainer>
      <StyledWrapper>
        <StyledColumn>
          <StyledTitle>{parse(data.title)}</StyledTitle>
          <StyledParagraph>{parse(data.text)}</StyledParagraph>
        </StyledColumn>
      </StyledWrapper>
    </StyledContainer>
  </StyledBackground>
);

export default EveryAngle;
