import React, { useEffect, useRef } from 'react';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { isBrowser } from 'react-device-detect';
import { sliderAnimation } from 'theme/Animations';
import parse from 'html-react-parser';
import withLocale from 'hoc/withLocale';

import {
  StyledSectionWrapper,
  StyledHeading,
  StyledContent,
  StyledSwiper,
  StyledImg,
  StyledSubheading,
} from './TitleDescriptionSliderStyles';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const sliderParams = {
  slidesPerView: 'auto',
  slidesPerGroup: 1,
  centeredSlides: true,
  spaceBetween: 16,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  loop: false,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    renderBullet: (index, className) => `<div class="${className}"></div>`,
  },
  navigation: {
    nextEl: '.swiper-button-next.slider-arrow',
    prevEl: '.swiper-button-prev.slider-arrow',
  },
};

const TitleDescriptionSlider = ({ data, currentLocale }) => {
  let container = useRef(null);
  let heading = useRef(null);
  let slider = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      sliderAnimation(container, heading, slider);
    }
  }, []);

  return (
    <StyledSectionWrapper
      ref={el => {
        container = el;
      }}
    >
      <StyledContent>
        <StyledHeading
          ref={el => {
            heading = el;
          }}
        >
          {parse(data.title)}
        </StyledHeading>
        <StyledSubheading>{parse(data.text)}</StyledSubheading>
      </StyledContent>

      <StyledSwiper
        ref={el => {
          slider = el;
        }}
      >
        <Swiper {...sliderParams}>
          {data?.images &&
            data.images.map((image, idx) => {
              const gatsbyImage = getImage(image.localFile);
              const alt = image.alternativeText ?? 'slider image';
              const bgImage = convertToBgImage(gatsbyImage);
              return (
                <StyledImg
                  onClick={() => {
                    if (image.caption) {
                      const links = image.caption.split('|');
                      window.open(
                        currentLocale === 'en' && links[1]
                          ? links[1]
                          : links[0],
                        '_blank'
                      );
                    }
                  }}
                  key={idx}
                  Tag="div"
                  {...bgImage}
                  alt={alt}
                />
              );
            })}
        </Swiper>
      </StyledSwiper>
    </StyledSectionWrapper>
  );
};

export default withLocale(TitleDescriptionSlider);
