import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledOurProjects = styled('div')`
  width: 100%;
  position: relative;
  background: linear-gradient(180deg, #454545 -100%, #1b1b1b 100%);
  overflow: hidden;
`;

export const StyledContainer = styled('div')`
  padding: 4.7rem 0 7rem;
  ${({ theme }) => theme.mq.lg} {
    padding: 10rem 0 15rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding: 21rem 0 18rem;
  }
`;

export const StyledMainSection = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.mq.xxl} {
    flex-direction: row-reverse;
    justify-content: flex-end;
    max-width: 100%;
  }
`;

export const StyledImg = styled(GatsbyImage)`
  cursor: pointer;
  border-radius: 4px;
  overflow: hidden;
  max-width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.mq.smm} {
    max-width: none;
    position: static;
  }
`;

export const StyledSection = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  padding-top: 8rem;
  ${({ theme }) => theme.mq.lg} {
    max-width: 1000px;
    flex-direction: row;
    padding-top: 14rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1500px;
  }
`;

export const StyledContent = styled('div')`
  display: block;
  max-width: 950px;
`;

export const StyledTitle = styled('h2')`
  position: relative;
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 3rem 3rem 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${({ theme }) => theme.mq.smm} {
    padding-left: 5rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding-bottom: 8rem;
    padding-left: 3rem;
    font-size: 6.4rem;
    line-height: 7rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 7.8rem;
    line-height: 8.4rem;
    padding: 4rem 3rem 8rem 10rem;
  }
  ${({ theme }) => theme.mq.xxxl} {
    font-size: 7.8rem;
    line-height: 8.4rem;
    text-align: left;
  }
  @media (min-width: 1910px) {
    font-size: 10.9rem;
    line-height: 10.2rem;
  }
  span {
    display: block;
  }
`;

export const StyledSwiper = styled('div')`
  max-width: 1250px;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 9;
  ${({ theme }) => theme.mq.xxl} {
    max-width: 800px;
  }
  ${({ theme }) => theme.mq.xxxl} {
    max-width: 1250px;
    transform: translateX(-100px);
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 10;
    height: calc(100% - 30px);
  }
  &::before {
    left: 0;
    background: linear-gradient(90deg, #2c2c2c 0%, rgba(46, 46, 46, 0) 90.06%);
    ${({ theme }) => theme.mq.xxxl} {
      left: 0;
      width: 120px;
    }
  }
  &::after {
    right: 0;
    background: linear-gradient(
      -90deg,
      #2c2c2c -36.75%,
      rgba(46, 46, 46, 0) 89.46%
    );
    ${({ theme }) => theme.mq.xxl} {
      display: none;
    }
  }
  & > img {
    display: none;
    position: absolute;
    top: -20%;
    left: 20%;
    ${({ theme }) => theme.mq.lg} {
      display: block;
    }
  }
  .swiper-pagination {
    bottom: 0 !important;
  }
`;

export const StyledParagraph = styled('p')`
  font-size: 1.8rem;
  line-height: 3.2rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 2rem;
  position: relative;
  z-index: 2;
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.4rem;
    line-height: 4.8rem;
  }
  span {
    display: block;
  }
`;
