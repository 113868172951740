import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';

export const StyledHeroBackground = styled(BackgroundImage)`
  width: 100%;
  min-height: 100vh;
  position: relative;
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTitle = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  padding-bottom: 28px;
  ${({ theme }) => theme.mq.xl} {
    padding-bottom: 37px;
  }
`;

export const StyledTitleText = styled.h1`
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -2px;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.mq.smm} {
    font-size: 70px;
    line-height: 82px;
  }
`;

export const StyledSubtitle = styled('span')`
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  max-width: 80vw;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.smm} {
    font-size: 3.5em;
    line-height: 5rem;
  }

  span {
    line-height: 30px;
  }
`;

export const StyledContact = styled('div')`
  display: none;
  position: absolute;
  bottom: 80px;
  left: 0;
  padding: 0.8rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;

export const StyledSocial = styled('div')`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3.6rem 5rem;
  ${({ theme }) => theme.mq.lg} {
    display: block;
  }
`;
