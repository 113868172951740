/* eslint-disable jsx-a11y/alt-text */
import React, { useRef, useEffect } from 'react';
import { isBrowser } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import { ourProjectsAnimation } from 'theme/Animations';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import OurProjectsShape from 'svg/OurProjectsShape.svg';
import withLocale from 'hoc/withLocale';
import parse from 'html-react-parser';

import {
  StyledOurProjects,
  StyledContainer,
  StyledMainSection,
  StyledTitle,
  StyledSwiper,
  StyledImg,
} from './OurProjectsStyles';

SwiperCore.use([Pagination, Autoplay]);

const sliderParams = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 16,
  autoplay: true,
  loop: false,
  breakpoints: {
    500: {
      slidesPerView: 2,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 23,
    },
    1800: {
      slidesPerView: 4,
      spaceBetween: 43,
    },
  },
  pagination: { clickable: true },
};

const OurProjects = ({ data, currentLocale }) => {
  let title = useRef(null);
  let sliderBg = useRef(null);
  let slider = useRef(null);
  let mainSection = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      ourProjectsAnimation(mainSection, title, slider, sliderBg);
    }
  }, []);

  return (
    <StyledOurProjects>
      <StyledContainer>
        <StyledMainSection
          ref={el => {
            mainSection = el;
          }}
        >
          <StyledTitle
            ref={el => {
              title = el;
            }}
          >
            <span>{parse(data.title)}</span>
          </StyledTitle>
          <StyledSwiper
            ref={el => {
              slider = el;
            }}
          >
            <img
              ref={el => {
                sliderBg = el;
              }}
              src={OurProjectsShape}
            />
            <Swiper {...sliderParams}>
              {data.images.map((image, idx) => {
                const gatsbyImage = getImage(image.localFile);
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    key={idx}
                    onClick={() => {
                      if (image.caption) {
                        const links = image.caption.split('|');
                        window.open(
                          currentLocale === 'en' && links[1]
                            ? links[1]
                            : links[0],
                          '_blank'
                        );
                      }
                    }}
                  >
                    <StyledImg
                      style={{ cursor: 'pointer' }}
                      image={gatsbyImage}
                      alt={image.alternativeText}
                    />
                  </div>
                );
              })}
            </Swiper>
          </StyledSwiper>
        </StyledMainSection>
      </StyledContainer>
    </StyledOurProjects>
  );
};

export default withLocale(OurProjects);
