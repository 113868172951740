import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import { isBrowser } from 'react-device-detect';
import { servicesAnimation } from 'theme/Animations';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import withLocale from 'hoc/withLocale';
import 'swiper/swiper-bundle.css';

import { useIntl } from 'gatsby-plugin-intl';

import {
  StyledServices,
  StyledSwiper,
  StyledImg,
  StyledContentWrapper,
  StyledHeading,
  StyledItems,
  StyledItem,
  StyledIconText,
  StyledBtn,
  StyledSliderWrapper,
  StyledLeftColumn,
  StyledColumnsWrapper,
  StyledContent,
  StyledTitleWrapper,
} from './ServicesStyles';

SwiperCore.use([Pagination, Autoplay]);

const Services = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);
  let items = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    if (isBrowser) {
      servicesAnimation(container, title, items);
    }
  }, []);

  const sliderParams = {
    slidesPerView: 1,
    centeredSlides: true,
    slidesPerGroup: 1,
    speed: 800,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };

  return (
    <>
      <StyledContent>
        <StyledTitleWrapper>
          <StyledBtn to={`/${data.link}`}>
            {intl.formatMessage({ id: 'see_all_products' })}
          </StyledBtn>
        </StyledTitleWrapper>
      </StyledContent>
      <StyledServices>
        <StyledColumnsWrapper>
          <StyledLeftColumn />
          <StyledContentWrapper
            ref={el => {
              container = el;
            }}
          >
            <StyledHeading
              ref={el => {
                title = el;
              }}
            >
              {parse(data.title)}
            </StyledHeading>
            <StyledItems
              ref={el => {
                items = el;
              }}
            >
              <StyledItem>
                <StyledIconText>{parse(data.list_item_1)}</StyledIconText>
              </StyledItem>
              <StyledItem>
                <StyledIconText>{parse(data.list_item_2)}</StyledIconText>
              </StyledItem>
              <StyledItem>
                <StyledIconText>{parse(data.list_item_3)}</StyledIconText>
              </StyledItem>
              <StyledItem>
                <StyledIconText>{parse(data.list_item_4)}</StyledIconText>
              </StyledItem>
              <StyledItem>
                <StyledIconText>{parse(data.list_item_5)}</StyledIconText>
              </StyledItem>
            </StyledItems>
          </StyledContentWrapper>
        </StyledColumnsWrapper>
        <StyledSliderWrapper>
          <StyledSwiper>
            <Swiper {...sliderParams}>
              {data.images.map((image, idx) => {
                const gatsbyImage = getImage(image.localFile);
                const bgImage = convertToBgImage(gatsbyImage);
                return <StyledImg key={idx} tag="div" {...bgImage} />;
              })}
            </Swiper>
          </StyledSwiper>
        </StyledSliderWrapper>
      </StyledServices>
    </>
  );
};

export default withLocale(Services);
