import styled from 'styled-components';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby-plugin-intl';

export const StyledContent = styled('div')`
  width: 100%;
  max-width: 600px;
  padding: 8rem 0 5rem;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md} {
    padding: 10rem 0 6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
    padding: 16rem 0 10rem;
  }
`;

export const StyledTitleWrapper = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledServices = styled('div')`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.footerBg};
  position: relative;
  flex-wrap: wrap;

  ${({ theme }) => theme.mq.md} {
    flex-wrap: nowrap;
  }
`;

export const StyledSliderWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  min-height: 100vh;

  ${({ theme }) => theme.mq.md} {
    max-width: 50%;
  }
`;
export const StyledSwiper = styled('div')`
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 9;

  .swiper-container {
    width: 100%;
    height: 100%;
  }
  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 4% !important;
  }
  .swiper-pagination {
    display: block !important;
  }
`;

export const StyledImg = styled(BackgroundImage)`
  display: block;
  width: 100%;
  height: 100%;
`;

export const StyledColumnsWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  overflow: hidden;
`;

export const StyledContentWrapper = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 4rem 0;
  margin: 0;

  ${({ theme }) => theme.mq.md} {
    max-width: 50%;
    padding: 8rem 0;
  }
`;

export const StyledLeftColumn = styled.div`
  max-width: 3%;
  width: 100%;

  ${({ theme }) => theme.mq.md} {
    max-width: 15%;
  }
`;

export const StyledHeading = styled('h2')`
  color: ${({ theme }) => theme.colors.textGray};
  font-size: 3.6rem;
  line-height: 5.4rem;
  font-weight: 600;
  margin-bottom: 4.3rem;

  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 10rem;
    font-size: 4.2rem;
    line-height: 5.4rem;
  }
  p {
    margin: 0;
  }
`;

export const StyledItems = styled('ul')`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
`;

export const StyledItem = styled('li')`
  position: relative;
  width: 100%;
  margin-bottom: 2rem;
  list-style: none;
  margin-left: 5rem;

  ${({ theme }) => theme.mq.md} {
    margin-left: 0;
  }

  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 3rem;
  }
  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    content: '';
    height: 2px;
    width: 60%;
    border-bottom: 2px solid ${({ theme }) => theme.colors.veryLightGray};
    top: 50%;
    left: -64%;
  }
`;

export const StyledIconText = styled('span')`
  color: ${({ theme }) => theme.colors.textGray};
  font-size: 1.8rem;
  line-height: 2.4rem;
  text-transform: uppercase;
  letter-spacing: -2px;

  ${({ theme }) => theme.mq.xl} {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }
`;

export const StyledBtn = styled(Link)`
  width: fit-content;
  padding: 0;
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textBrown};
  padding-left: 4rem;
  position: relative;
  margin-bottom: 3rem;

  ${({ theme }) => theme.mq.xl} {
    font-size: 2.8rem;
    line-height: 3.4rem;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 2px;
    width: 2.2rem;
    height: 2.2rem;
    background-color: ${({ theme }) => theme.colors.textBrown};

    ${({ theme }) => theme.mq.xl} {
      top: 3px;
      width: 2.8rem;
      height: 2.8rem;
    }
  }
`;
