import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby-plugin-intl';

export const StyledAboutUs = styled('div')`
  width: 100%;
  position: relative;
  min-height: 100vh;
`;

export const StyledContainer = styled('div')`
  max-width: 600px;
  min-height: 100vh;
  margin: 0 auto;
  ${({ theme }) => theme.mq.lg} {
    max-width: 950px;
  }
  ${({ theme }) => theme.mq.xxl} {
    max-width: 1400px;
  }
`;

export const StyledWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 3.8rem 2.5rem 6rem;
  overflow: hidden;
  height: 100%;
  ${({ theme }) => theme.mq.sm} {
    padding: 3.8rem 2.5rem 12rem;
  }
  ${({ theme }) => theme.mq.smm} {
    padding: 3.8rem 2.5rem 20rem;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    padding: 12rem 2.5rem 12rem;
  }
`;

export const StyledBubbleItem = styled('div')`
  &:nth-of-type(1) {
    position: absolute;
    width: 150px;
    height: 150px;
    left: -70px;
    bottom: 40px;
    border-radius: 50%;
    background: rgb(214, 203, 237);
    background: linear-gradient(
      180deg,
      rgba(214, 203, 237, 1) 80%,
      rgba(9, 9, 121, 0) 100%
    );
    ${({ theme }) => theme.mq.md} {
      width: 180px;
      height: 180px;
    }
    ${({ theme }) => theme.mq.xl} {
      width: 260px;
      height: 260px;
      left: -150px;
      bottom: 50px;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 320px;
      height: 320px;
      bottom: 100px;
    }
  }
  &:nth-of-type(2) {
    display: none;
    content: '';
    position: absolute;
    width: 58px;
    height: 58px;
    left: 220px;
    bottom: 150px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightOrange};
    ${({ theme }) => theme.mq.lg} {
      display: block;
    }
    ${({ theme }) => theme.mq.xxxl} {
      width: 88px;
      height: 88px;
      left: 300px;
      bottom: 230px;
    }
  }
  &:nth-of-type(3) {
    display: none;
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    left: 180px;
    bottom: 400px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.lightOrange};
    ${({ theme }) => theme.mq.xxxl} {
      display: block;
    }
  }
`;

export const StyledContent = styled('div')`
  color: ${({ theme }) => theme.colors.black};
  width: 100%;
  ${({ theme }) => theme.mq.lg} {
    width: 50%;
    padding-right: 3rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    padding-right: 10rem;
  }
`;

export const StyledTitle = styled('div')`
  font-size: 3.2rem;
  margin-bottom: 6rem;
  text-transform: uppercase;

  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
    margin-bottom: 13rem;
  }

  p {
    margin: 0;
  }
`;

export const StyledSubtitle = styled.div`
  font-size: 2.1rem;
  line-height: 2.6rem;
  margin-bottom: 3rem;
  text-transform: uppercase;
  font-weight: 700;

  ${({ theme }) => theme.mq.lg} {
    font-size: 2.7rem;
    line-height: 3.3rem;
    margin-bottom: 5rem;
  }

  p {
    margin: 0;
    color: ${({ theme }) => theme.colors.textBrown};
  }
`;

export const StyledParagraph = styled('div')`
  width: 65%;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 4rem;
  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 5rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    line-height: 3.2rem;
  }
`;

export const StyledImages = styled('div')`
  width: 100%;
  position: relative;
  margin-top: 40px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: 20px;
    width: 50%;
  }
  ${({ theme }) => theme.mq.xxl} {
    margin-top: 0;
  }
`;

export const StyledImage = styled('div')`
  position: relative;
  overflow: hidden;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

export const StyledImageImg = styled(GatsbyImage)`
  width: 100%;
  min-height: 30vw;
  height: 100%;
  object-fit: cover;
`;

export const StyledBtn = styled(Link)`
  width: fit-content;
  padding: 0;
  font-size: 2.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.textBrown};
  padding-left: 4rem;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 2px;
    width: 2.2rem;
    height: 2.2rem;
    background-color: ${({ theme }) => theme.colors.textBrown};
  }
`;
