import React, { useEffect, useRef } from 'react';
import parse from 'html-react-parser';
import { useIntl } from 'gatsby-plugin-intl';
import { isBrowser } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import { aboutUsAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';

import {
  StyledAboutUs,
  StyledContainer,
  StyledWrapper,
  StyledContent,
  StyledTitle,
  StyledParagraph,
  StyledImages,
  StyledImage,
  StyledImageImg,
  StyledBtn,
  StyledSubtitle,
} from './AboutUsStyles';

const AboutUs = ({ data }) => {
  const intl = useIntl();

  let container = useRef(null);
  let content = useRef(null);
  let image = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      aboutUsAnimation(container, image, content);
    }
  }, []);

  return (
    <StyledAboutUs
      ref={el => {
        container = el;
      }}
    >
      <StyledContainer>
        <StyledWrapper>
          <StyledContent
            ref={el => {
              content = el;
            }}
          >
            {data.title && <StyledTitle>{parse(data.title)}</StyledTitle>}
            {data.subtitle && (
              <StyledSubtitle>{parse(data.subtitle)}</StyledSubtitle>
            )}
            <StyledParagraph>{parse(data.text)}</StyledParagraph>
            <StyledBtn to={`/${data.link}`}>
              {intl.formatMessage({ id: 'read_more' })}
            </StyledBtn>
          </StyledContent>
          <StyledImages>
            <StyledImage
              ref={el => {
                image = el;
              }}
            >
              <StyledImageImg
                image={getImage(data.image.localFile)}
                alt={data.image.alternativeText ?? 'about_us'}
              />
            </StyledImage>
          </StyledImages>
        </StyledWrapper>
      </StyledContainer>
    </StyledAboutUs>
  );
};

export default withLocale(AboutUs);
