import styled from 'styled-components';

export const StyledBackground = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${({ $url }) => $url});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`;

export const StyledContainer = styled('div')`
  width: 100%;
  height: 100%;
  min-height: fit-content;
  padding: 2rem 1.5rem 6rem;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mq.md} {
    min-height: 100vh;
    padding: 2rem 5rem 8rem;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 12rem 5rem 12rem;
  }
  ${({ theme }) => theme.mq.xl} {
    padding: 16rem 5rem 16rem;
  }
`;

export const StyledWrapper = styled('div')`
  max-width: 140rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
`;

export const StyledTitle = styled('h2')`
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  font-size: 3.2rem;
  margin: 0 0 3rem;
  text-align: left;
`;

export const StyledColumn = styled('div')`
  max-width: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 7rem;
`;

export const StyledParagraph = styled('div')`
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
`;
