export const TemplateEnum = {
  AboutUs: 'TemplateTitleTextLinkImgONas',
  Film: 'TemplateFilm',
  TitleSlider: 'TemplateTitleSliderDesignerskieOpakowania',
  TitleWithBackgrounds: 'TemplateKomponentRelacyjny',
  TitleDescriptionSlider: 'TemplateTypingTextStawiamyKropkeNadI',
  TitleListImageLeft: 'TemplateTitleDescriptionImagesCenterListKremyBb',
  TitleSliderLeft: 'TemplateTitleTextSliderMakijaz',
  ListWithBackground: 'TemplateListBackgroundAloesKoiNawilza',
  TitleListImageRight: 'TemplateTitleSliderTitle2ContentMaskiWPlachcie',
  ContactForm: 'TemplateContactForm',
  EveryAngle: 'TemplatePodKazdymKatem',

  // TextSingleImageLeftAndRight: 'TemplateTextSingleImageLeftWyjatkoweFormuly',
  // TitleTextSliderRight: 'TemplateTitleTextSliderRightOczyszczanieTwarzy',
  // TitleTextBigImageLeft: 'TemplateTitleTextBigImageLeftNawilzamy',
  // TitleTextListSlider: 'TemplateTitleTextListSliderPielegnacja',
  // TitleTextImagesGrid: 'TemplateTitleTextImagesGridPropozycji',
  // TitleTextLeftTextRight: 'TemplateTitleTextText2MamyToCos',
  // TitleListWithBackground: 'TemplateTitleListBackgroundWieleZalet',
  // TitleTextMap: 'TemplateTitleTextMap',
};
