import React, { useEffect, useRef } from 'react';
import { isBrowser } from 'react-device-detect';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import parse from 'html-react-parser';

import { cardboardsAnimation } from 'theme/Animations';

import {
  StyledClean,
  StyledContent,
  StyledTitle,
  StyledProducts,
  StyledProduct,
  StyledProductTitle,
} from './CardboardsStyles';

const Cardboards = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      cardboardsAnimation(container, title);
    }
  }, []);

  return (
    <StyledClean
      ref={el => {
        container = el;
      }}
    >
      <StyledContent>
        <StyledTitle
          ref={el => {
            title = el;
          }}
        >
          {parse(data.title)}
        </StyledTitle>
      </StyledContent>
      <StyledProducts>
        {data.Cartboard_element.map((element, idx) => {
          const gatsbyImage = getImage(element.image.localFile);
          const bgImage = convertToBgImage(gatsbyImage);
          return (
            <StyledProduct tag="div" {...bgImage} key={idx}>
              <StyledProductTitle>{parse(element.title)}</StyledProductTitle>
            </StyledProduct>
          );
        })}
      </StyledProducts>
    </StyledClean>
  );
};

export default Cardboards;
