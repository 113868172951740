import React, { useRef, useEffect } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import { isBrowser } from 'react-device-detect';
import withLocale from 'hoc/withLocale';
import { featuresAnimation } from 'theme/Animations';
import parse from 'html-react-parser';

import {
  StyledBackground,
  StyledWrapper,
  StyledPanel,
  StyledNumber,
  StyledTitle,
} from './FeaturesStyles';

const Features = ({ data }) => {
  let container = useRef(null);
  let element1 = useRef(null);
  let element2 = useRef(null);
  let element3 = useRef(null);
  let element4 = useRef(null);
  let element5 = useRef(null);

  const gatsbyImage = getImage(data.background.localFile);
  const bgImage = convertToBgImage(gatsbyImage);

  useEffect(() => {
    if (isBrowser) {
      featuresAnimation(container, [
        element1,
        element2,
        element3,
        element4,
        element5,
      ]);
    }
  }, []);

  return (
    <StyledBackground
      tag="div"
      {...bgImage}
      style={{
        backgroundPosition: '80% 50%',
      }}
    >
      <StyledWrapper
        ref={el => {
          container = el;
        }}
      >
        <StyledPanel
          ref={el => {
            element1 = el;
          }}
          $color={data.color}
        >
          <StyledNumber>01</StyledNumber>
          <StyledTitle>{parse(data.list_item_1)}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element2 = el;
          }}
          $color={data.color}
        >
          <StyledNumber>02</StyledNumber>
          <StyledTitle>{parse(data.list_item_2)}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element3 = el;
          }}
          $color={data.color}
        >
          <StyledNumber>03</StyledNumber>
          <StyledTitle>{parse(data.list_item_3)}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element4 = el;
          }}
          $color={data.color}
        >
          <StyledNumber>04</StyledNumber>
          <StyledTitle>{parse(data.list_item_4)}</StyledTitle>
        </StyledPanel>
        <StyledPanel
          ref={el => {
            element5 = el;
          }}
          $color={data.color}
        >
          <StyledNumber>05</StyledNumber>
          <StyledTitle>{parse(data.list_item_5)}</StyledTitle>
        </StyledPanel>
      </StyledWrapper>
    </StyledBackground>
  );
};

export default withLocale(Features);
