import React, { useEffect, useRef } from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { graphql, useStaticQuery } from 'gatsby';
import Typewriter from 'typewriter-effect';
import { heroAnimation } from 'theme/Animations';
import withLocale from 'hoc/withLocale';
import Social from '../../components/Social/Social';

import {
  StyledHeroBackground,
  StyledTitle,
  StyledSubtitle,
  StyledSocial,
  StyledTitleText,
} from './HeaderStyles';

const Header = () => {
  const intl = useIntl();

  let title = useRef(null);
  let subtitle = useRef(null);

  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "hero" }) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      strapiFooter {
        linkedin
        facebook
        youtube
        instagram
      }
    }
  `);

  const { linkedin, facebook, youtube, instagram } = data.strapiFooter;

  const socialsData = [
    {
      id: 1,
      name: 'Linkedin',
      url: linkedin,
    },
    {
      id: 2,
      name: 'Facebook',
      url: facebook,
    },
    {
      id: 3,
      name: 'Youtube',
      url: youtube,
    },
    {
      id: 4,
      name: 'Instagram',
      url: instagram,
    },
    {
      id: 5,
      name: 'Euro',
      url: intl.formatMessage({ id: 'euro_url' }),
    },
  ];

  useEffect(() => {
    heroAnimation([title, subtitle]);
    window.scrollTo(0, 0);
    document.body.style.overflowY = 'hidden';
    setTimeout(() => {
      document.body.style.overflowY = 'scroll';
    }, 2500);
  }, []);

  return (
    <StyledHeroBackground tag="div" fluid={data.file.childImageSharp.fluid}>
      <div>
        <StyledTitle
          ref={el => {
            title = el;
          }}
        >
          <StyledTitleText>
            {intl.formatMessage({ id: 'header_title_part_one' })}
          </StyledTitleText>
          <StyledTitleText>
            {intl.formatMessage({ id: 'header_title_part_two' })}
          </StyledTitleText>
        </StyledTitle>
        <StyledSubtitle
          ref={el => {
            subtitle = el;
          }}
        >
          <Typewriter
            options={{
              strings: [intl.formatMessage({ id: 'header_subtitle' })],
              autoStart: true,
              loop: true,
              delay: 70,
              pauseFor: 5000,
            }}
          />
        </StyledSubtitle>
        <StyledSocial>
          <Social socialsData={socialsData} />
        </StyledSocial>
      </div>
    </StyledHeroBackground>
  );
};

export default withLocale(Header);
