import React, { useEffect, useRef } from 'react';
import 'swiper/swiper-bundle.css';
import Swiper from 'react-id-swiper';
import SwiperCore, { Pagination, Autoplay } from 'swiper/core';
import { isBrowser } from 'react-device-detect';
import { usageAnimation } from 'theme/Animations';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import withLocale from 'hoc/withLocale';
import Sofa from 'svg/Sofa';
import Plant from 'svg/Plant';
import Paint from 'svg/Paint';
import Box from 'svg/Box';
import Cut from 'svg/Cut';
import parse from 'html-react-parser';

import {
  StyledUsage,
  StyledSwiper,
  StyledImg,
  StyledContentWrapper,
  StyledHeading,
  StyledItems,
  StyledItem,
  StyledIconText,
  StyledIconBorder,
} from './UsageStyles';

SwiperCore.use([Pagination, Autoplay]);

const Usage = ({ data }) => {
  let container = useRef(null);
  let title = useRef(null);
  let items = useRef(null);

  useEffect(() => {
    if (isBrowser) {
      usageAnimation(container, title, items);
    }
  }, []);

  const sliderParams = {
    slidesPerView: 1,
    centeredSlides: true,
    slidesPerGroup: 1,
    speed: 800,
    autoplay: {
      delay: 3000,
      disableOnInteraction: false,
    },
    loop: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      500: {
        pagination: {
          dynamicBullets: data.images[0].length > 10 && true,
        },
      },
      992: {
        pagination: {
          dynamicBullets: data.images[0].length > 15 && true,
        },
      },
      1200: {
        pagination: {
          dynamicBullets: data.images[0].length > 13 && true,
        },
      },
      1920: {
        pagination: {
          dynamicBullets: data.images[0].length > 15 && true,
        },
      },
    },
  };

  return (
    <>
      <StyledUsage>
        <StyledSwiper>
          <Swiper {...sliderParams}>
            {data.images.map((image, idx) => {
              const gatsbyImage = getImage(image.localFile);
              const bgImage = convertToBgImage(gatsbyImage);
              return <StyledImg key={idx} tag="div" {...bgImage} />;
            })}
          </Swiper>
        </StyledSwiper>

        <StyledContentWrapper
          ref={el => {
            container = el;
          }}
        >
          <StyledHeading
            ref={el => {
              title = el;
            }}
          >
            {parse(data.title)}
          </StyledHeading>

          <StyledItems
            ref={el => {
              items = el;
            }}
          >
            <StyledItem>
              <StyledIconBorder>
                <Sofa />
              </StyledIconBorder>

              <StyledIconText>{parse(data.list_item_1)}</StyledIconText>
            </StyledItem>

            <StyledItem>
              <StyledIconBorder>
                <Plant />
              </StyledIconBorder>

              <StyledIconText>{parse(data.list_item_2)}</StyledIconText>
            </StyledItem>

            <StyledItem>
              <StyledIconBorder>
                <Paint />
              </StyledIconBorder>

              <StyledIconText>{parse(data.list_item_3)}</StyledIconText>
            </StyledItem>

            <StyledItem>
              <StyledIconBorder>
                <Box />
              </StyledIconBorder>

              <StyledIconText>{parse(data.list_item_4)}</StyledIconText>
            </StyledItem>

            <StyledItem>
              <StyledIconBorder>
                <Cut />
              </StyledIconBorder>

              <StyledIconText>{parse(data.list_item_5)}</StyledIconText>
            </StyledItem>
          </StyledItems>
        </StyledContentWrapper>
      </StyledUsage>
    </>
  );
};

export default withLocale(Usage);
